import React, { Component } from "react";
import LandingPage from "./views/home/LandingPage";
const Route = require('react-router-dom').Route

class Routes extends Component {

  render() {
    return (
      <>
            <Route path="/" exact strict component={LandingPage} />
      </>
    );
  }
}
export default Routes;