import { Button, Divider, Grid } from "@material-ui/core";
import { useState } from "react";
import Appbar from "./Appbar";
import phone from "../../assets/img/phone.svg";
import side1 from "../../assets/img/side1.svg";
import side2 from "../../assets/img/side2.svg";
import side3 from "../../assets/img/side3.svg";
import side4 from "../../assets/img/side4.svg";
import side5 from "../../assets/img/side5.svg";
import side6 from "../../assets/img/side6.svg";
import apple from "../../assets/img/apple.svg";
import google from "../../assets/img/google.svg";
import LandingTabs from "./LandingTabs";

export default function LandingPage() {

  return (
    <div>
        <Appbar/>
        <div className="main">
        <Grid container spacing={8} className="maxwidthauto">
            <Grid item lg={6} xs={12} className="text-center section-one">
                <div style={{color: '#000'}} className="bold bigtext">Stay on top of your FINANCES.</div>
                <div style={{marginTop: 30}}>
                    <div className="smalltext">
                        TRACK FINANCES, SUBSCRIPTIONS 
                        and stay on BUDGET across AFRICA in one place.
                    </div>
                    <div className="text-purple bold mt-4 showhidetext" style={{fontSize: 24}}>First account free, #1000 for additional accounts</div>
                </div>
                <Button className="bg-purple text-white bold w-full" style={{marginTop: 30}}>track my spending now</Button>
            </Grid>
            <Grid item lg={6} xs={12}>
                <img src={phone} className="w-full" style={{marginTop: 30}}/>
            </Grid>
        </Grid>


        <Divider className="text-purple" style={{color: ''}}/>
        <Grid container spacing={8} className="maxwidthauto">
            <Grid item lg={6} xs={12}>
                <LandingTabs/>
                <img src={side1}  className="w-full" style={{marginTop: 50}}/>
            </Grid>
            <Grid item lg={6} xs={12} className="text-center m-top-130">
                <div className="text-purple bold mediumtext">Keep track of your transactions.</div>
                <div className="m-top-30">
                    <div className="text-purple tinytext">
                        Cashmark is easy to use and quick-to-learn. You can manage your finances better than ever before. It’s simple, you just link your bank account and Cashmark does the thinking for you. Our AI algorithms will automatically sort, categorize, estimate and categorize your transactions into personalized reports that are easy to read.
                    </div>
                </div>
                <Button className="bg-purple text-white bold w-full" style={{marginTop: 30}}>start tracking</Button>
            </Grid>
        </Grid>


        <Divider className="text-purple" style={{color: ''}}/>
        <Grid container spacing={8} className="maxwidthauto">
            <Grid item lg={6} xs={12} className="text-center m-top-130">
                <div className="text-purple bold mediumtext">Monitor your business.</div>
                <div className="m-top-30">
                    <div className="text-purple tinytext">
                        We make it easy for business owners to get a handle on their personal finances.
                        More than just accounting software, we also provide meaningful insights into 
                        your income, spending habits and debt protection.
                    </div>
                </div>
                <Button className="bg-purple text-white bold w-full" style={{marginTop: 30}}>monitor now</Button>
            </Grid>
            <Grid item lg={6} xs={12}>
                <LandingTabs/>
                <img src={side2}  className="w-full" style={{marginTop: 50}}/>
            </Grid>
        </Grid>


        <Divider className="text-purple" style={{color: ''}}/>
        <Grid container spacing={8} className="maxwidthauto">
            <Grid item lg={6} xs={12}>
                <LandingTabs/>
                <img src={side3}  className="w-full" style={{marginTop: 50}}/>
            </Grid>
            <Grid item lg={6} xs={12} className="text-center m-top-130">
                <div className="text-purple bold mediumtext">It's the age of crypto.</div>
                <div className="m-top-30">
                    <div className="text-purple tinytext">
                    Our system is tailored for the cryptocurrency user. 
                    It’s a multicoin tracker that allows to gather data about balances, swaps, and purchases of various forms of digital money. We’ve collected the most popular crypto coins, including BTC, ETH, DOGE and LTC as well as more than 3000 alternative cryptocurrencies.                    </div>
                </div>
                <Button className="bg-purple text-white bold w-full" style={{marginTop: 30}}>sync coins</Button>
            </Grid>
        </Grid>


        <Divider className="text-purple" style={{color: ''}}/>
        <Grid container spacing={8} className="maxwidthauto">
            <Grid item lg={6} xs={12} className="text-center m-top-80">
                <div className="text-purple bold mediumtext">It's your money.</div>
                <div className="m-top-30">
                    <div className="text-purple tinytext">
                    Cashmark is a personal budgeting tool that learns about you, 
                    allows you to set your own priorities, and helps you meet your goals.

                    We pride ourselves in being the budgeting tool that helps you get rid of debt and save up for that trip to Bali, or for any dream you have.
                    </div>
                </div>
                <Button className="bg-purple text-white bold w-full" style={{marginTop: 30}}>plan this summer trip</Button>
            </Grid>
            <Grid item lg={6} xs={12}>

                <img src={side4}  className="w-full" style={{marginTop: 50}}/>
            </Grid>
        </Grid>


        <Divider className="text-purple" style={{color: ''}}/>
        <Grid container spacing={8} className="maxwidthauto">
            <Grid item lg={6} xs={12}>
        
                <img src={side5}  className="w-full m-top-50"/>
            </Grid>
            <Grid item lg={6} xs={12} className="text-center m-top-60">
                <div className="text-purple bold mediumtext">The PAYWALL.</div>
                <div className="m-top-30">
                    <div className="text-purple tinytext">
                    Automatically identifies your recurring bills and subscriptions, 
                    and cancel subscriptions.
                    Our system learns how to save you money and lets you know exactly 
                    what you can afford so that you never miss.
                    </div>
                </div>
                <Button className="bg-purple text-white bold w-full" style={{marginTop: 30}}>list my subscriptions</Button>
            </Grid>
        </Grid>


        <Divider className="text-purple" style={{color: ''}}/>
        <Grid container spacing={8} className="maxwidthauto">
            <Grid item lg={6} xs={12} className="text-center m-top-130">
                <div className="text-purple bold mediumtext">Search like a PRO.</div>
                <div className="m-top-30">
                    <div style={{fontSize: 16}} className="text-purple">
                        Advanced search for your transactions, want to see and share 
                        with anyone as evidence of payment.
                    </div>
                </div>
            </Grid>
            <Grid item lg={6} xs={12}>

                <img src={side6}  className="w-full m-top-50"/>
            </Grid>
        </Grid>

        <Divider className="text-purple" style={{color: ''}}/>
        <div style={{color: '#0A0E61'}} className="bold text-center bigtext m-top">
        Your finances, safe and secure
        </div>
        <div style={{paddingTop: 40}}>
        <div style={{color: '#000', maxWidth: 500, margin: 'auto', padding: '0px 20px 0px 20px'}} className="text-center smalltext">
        Our top priority is keeping your data secure with multiple measures, like secure 256-bit encryption and multi-factor authentication, we protect your info like it’s our own.
        </div>
        <div className="text-center bold" style={{marginTop: 40, color: '#479F01'}}>Learn more about our security</div>
        </div>

        <Divider className="text-purple" style={{marginTop: 50}}/>
        <div className="text-center bold bigtext" style={{ marginTop: 100}}>Download CashMark today</div>
        <div style={{marginTop: 30}} className="text-center">
            <img src={google} className="google"/>
            <img src={apple} className="apple"/>
        </div>

        <div style={{background: '#F5EAF9', height: 80, marginTop: 100, color: '#6A6262'}} className="w-full">
            <div style={{paddingTop: 30}}>
            <div className="fs-14 absolute" style={{marginLeft: 80}}>&copy; 2021 Casmark. all rights reserved.</div>
            <div className="fs-14 showhidetext" style={{textAlign: 'right', marginRight: 80}}>Follow us on twitter</div>
            </div>
        </div>
        </div>
    </div>
  );
}
